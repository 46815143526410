.select-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding-left: 16px;
  padding-right: 10px;
  margin-left: auto;
  border-radius: 4px;
  border: 1px solid var(--gray-400);
  background: #fff;

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
}

.select-menu-full-width,
.select-menu-full-width .select-menu-toggle {
  width: 100% !important;
}

.select-menu-menu {
  margin-top: 16px;
  border: 1px solid #dce1e4;
  box-shadow: 0px 15px 35px rgba(132, 145, 155, 0.45);
  background: #fff;
  width: 100%;
}

.select-menu-toggle:disabled {
  cursor: not-allowed;
  background: var(--gray-100);
}
.select-menu-toggle:disabled input {
  cursor: not-allowed;
  background: var(--gray-100);
}

.select-menu-menu:after,
.select-menu-menu:before {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  display: inline-block;
}

.select-menu-menu:before {
  top: -11px;
  right: 17px;
  border-width: 0px 10px 11px 10px;
  border-color: transparent transparent #dce1e4 transparent;
  z-index: 2;
}

.select-menu-menu:after {
  top: -9px;
  right: 18px;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #fff transparent;
  z-index: 3;
}

.select-menu-search {
  padding: 7px 14px 14px;
  border-bottom: 1px solid var(--gray-300);
}

.select-menu-items {
  max-height: 350px;
  overflow: auto;
}

.select-menu-clear {
  position: absolute;
  top: 12px;
  right: 38px;
  width: 20px;
  height: 20px;
  line-height: 22px;
  padding: 0;
  border: 0;
  border-radius: 2px;
  background: none;
  -webkit-appearance: none;
}

.select-menu-clear:hover {
  background-color: var(--gray-100);
}
.select-menu-clear > .material-icons {
  font-size: 16px;
  line-height: inherit;
}
