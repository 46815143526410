.logo {
  /* display: block; */
  padding: 11px 26px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1600px) {
  .logo {
    padding-top: 21px;
    padding-bottom: 21px;
    padding-right: 42px;
    padding-left: 62px;
  }
}
