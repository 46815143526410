.inactive {
  filter: grayscale(1);
  opacity: 0.8;
  background: var(--gray-100);
}

.emailRow {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.iconNotif {
  height: 1.5rem;
  width: 1.5rem;
}

.activeCheckbox {
  position: absolute;
  right: 10px;
  top: 13px;
}
